import * as React from 'react'
import loadable from '@loadable/component'

const FieldSet = loadable(() => import('/src/components/Form/FieldSet'))
const FieldSpacer = loadable(() => import('/src/components/Form/FieldSpacer'))
const SelectDay = loadable(() =>
    import('/src/components/Form/Specialised/SelectDay')
)
const SelectMonth = loadable(() =>
    import('/src/components/Form/Specialised/SelectMonth')
)
const SelectYear = loadable(() =>
    import('/src/components/Form/Specialised/SelectYear')
)

const StepDob = ({ isSubmitting, isSubmitted }) => {
    return (
        <FieldSet legend={'Date of Birth'} required>
            <FieldSpacer grow={'stretch'}>
                <SelectDay
                    name={'dobDay'}
                    isSubmitting={isSubmitting}
                    isSubmitted={isSubmitted}
                />
            </FieldSpacer>

            <FieldSpacer grow={'stretch'}>
                <SelectMonth
                    name={'dobMonth'}
                    isSubmitting={isSubmitting}
                    isSubmitted={isSubmitted}
                />
            </FieldSpacer>

            <FieldSpacer grow={'stretch'}>
                <SelectYear
                    name={'dobYear'}
                    minAge={18}
                    maxAge={80}
                    isSubmitting={isSubmitting}
                    isSubmitted={isSubmitted}
                />
            </FieldSpacer>
        </FieldSet>
    )
}

export default StepDob
